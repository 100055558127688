import React from "react"
import { graphql } from "gatsby"

import SignIn from "../components/account/pages/sign-in"

const SignInPage = ({ data }) => {
  return <SignIn data={data} />
}

export default SignInPage

export const pageQuery = graphql`
  {
    tile: contentfulTile(
      title: { eq: "account-login" }
      node_locale: { eq: "en-US" }
    ) {
      id
      backgroundImage {
        gatsbyImageData
      }
    }
  }
`
